import React from "react";
import "./maintenance.css";
const Maintenance = () => {
    return (
        <div id="maintenance">
            <h1 data-aos="fade-up">תחזוקה</h1>
            <h2 data-aos="fade-up">
                כאן תקבלו הסבר מקיף על שירותי התחזוקה השונים, תחזוקה זה בעצם
                אומר שאני מתחזק את האתר ואעשה את השינויים שתבקשו, אפשר לשנות
                (צבע, גודל, תמונות ועוד..) *הוספה של חלק או דף היא בתוספת תשלום
            </h2>
            <ul>
                <li data-aos="fade-up">
                    <h3>חבילה מספר 1 - ריטיינר</h3>
                    <p>
                        - 200 שקלים בחודש
                        <br /> - כמה שינויים שתרצו
                        <br /> - התשלום הוא בכל תחילת חודש של תחזוקה
                    </p>
                    <p>
                        <span>למי זה מתאים?</span>
                        <br />
                        מי שמבצע שינויים קבוע באתר ולא פעם ב
                    </p>
                </li>
                <li data-aos="fade-up">
                    <h3>חבילה מספר 2 - תשלום שעתי</h3>
                    <p>
                        - תגידו לי איזה שינוי תרצו אני אעריך כמה זמן יקח והגבה
                        100 ש"ח לשעה, אם יקח פחות משעה אז לפי 15,30,45 דקות
                    </p>
                    <p>
                        <span>למי זה מתאים?</span>
                        <br />
                        למי שלא משנה קבוע את האתר ומשנה פעם ב
                    </p>
                </li>
            </ul>
            <h4 data-aos="fade-up">
                חשוב לציין שלאחר בניית הדף תקבלו 30 יום תחזוקה בחינם!
            </h4>
        </div>
    );
};

export default Maintenance;
